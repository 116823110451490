import React, { Component, Fragment } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import cx from 'classnames';

import IconClose from 'components/icons/Close';
import { observer, inject } from 'mobx-react';
import css from './style.css';

@inject('mobxStore')
@observer
class Modal extends Component {
  state = {
    fadeAnimation: true,
  };

  componentDidMount() {
    this.target = document.getElementById('modalContent');
    window.addEventListener('keyup', this.handleKeyUp);
    disableBodyScroll(this.target);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  handleKeyUp = (e) => {
    if (e.keyCode === 27 || e.code === 'Escape') {
      this.closeModal();
    }
  }

  closeModal = () => {
    enableBodyScroll(this.targetElement);
    setTimeout(() => {
      this.props.handleModalClose('');
    }, 650);
    this.setState(prevState => ({
      fadeAnimation: !prevState.fadeAnimation,
    }));
  }

  render() {
    const {
      mobxStore: {
        magicLinkStore,
      },
    } = this.props;
    return (
      <Fragment>
        <div
          className={cx(
            this.state.fadeAnimation ? 'fade-in' : 'fade-out', css['modal-bg'],
          )}
        />
        <div
          id="modal"
          className={cx(
            this.state.fadeAnimation ? 'fade-in' : 'fade-out', 'modal', (magicLinkStore.sent && magicLinkStore.status === 200) ? 'magic-link-sent' : '',
          )}
        >
          <div className={css['modal-content']} id="modalContent">
            {this.props.children}
          </div>
          <button
            className={css.modalCloseIcon}
            onClick={() => this.closeModal()}
          >
            <IconClose
              className={css.modalCloseIconImage}
            />
          </button>
        </div>
      </Fragment>
    );
  }
}

export default Modal;
