import React, { Component } from 'react';
import cx from 'classnames';

import IconClose from 'components/icons/Close';
import css from './style.css';

class Overlay extends Component {
  state = {
    fadeAnimation: true,
  };

  componentDidMount() {
    document.querySelector('body').style.overflow = 'hidden';
    window.addEventListener('keyup', this.handleKeyUp);
  }

  handleKeyUp = (e) => {
    if (e.keyCode === 27 || e.code === 'Escape') {
      this.closeOverlay();
    }
  }

  closeOverlay = () => {
    document.querySelector('body').style.overflow = 'auto';
    setTimeout(() => {
      this.props.handleOverlayClose('');
    }, 650);
    this.setState(prevState => ({
      fadeAnimation: !prevState.fadeAnimation,
    }));
  }

  render() {
    return (
      <div
        id="overlay"
        className={cx(
          this.state.fadeAnimation ? 'fade-in' : 'fade-out', css.overlay,
        )}
      >
        <div className={css['overlay-content']}>
          {this.props.children}
        </div>
        <button
          className={cx(css['slide-up'], css['overlay-close'])}
          onClick={() => this.closeOverlay()}
        >
          <IconClose strokeColor="#fff" />
        </button>
      </div>
    );
  }
}

export default Overlay;
