import React, { Component } from 'react';
import Head from 'next/head';
import { REACT_APP_ZUORA_HPM_HOST } from 'env';

// eslint-disable-next-line import/no-absolute-path
import '/static/css/variables.css';
// eslint-disable-next-line import/no-absolute-path
import '/static/css/fonts.css';
// eslint-disable-next-line import/no-absolute-path
import '/static/css/base.css';
// eslint-disable-next-line import/no-absolute-path
import '/static/css/animations.css';
// eslint-disable-next-line import/no-absolute-path
import '/static/css/typography.css';
// eslint-disable-next-line import/no-absolute-path
import '/static/css/form.css';
// eslint-disable-next-line import/no-absolute-path
import '/static/css/button.css';
// eslint-disable-next-line import/no-absolute-path
import '/static/css/webp-backgrounds.css';
// eslint-disable-next-line import/no-absolute-path
import '/static/css/sunrise-pages.css';

class PageHead extends Component {
  render() {
    return (
      <Head>
        <title>{this.props.title || 'Plume'}</title>
        <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no,user-scalable=no" />
        <script type="text/javascript" src={REACT_APP_ZUORA_HPM_HOST} />
        <link rel="icon" type="image/x-icon" href="/static/favicon.ico" />
      </Head>
    );
  }
}

export default PageHead;
