const US_STORE = {
  prefix: '/us',
  storeCountryId: 'us',
  flagImage: '/static/images/flag-us.png',
  switchLabel: 'Switch to US',
  landingPage: '/us',
};
const UK_STORE = {
  prefix: '/uk',
  storeCountryId: 'uk',
  flagImage: '/static/images/flag-uk.png',
  switchLabel: 'Switch to UK',
  landingPage: '/uk',
};
const CH_STORE = {
  prefix: '/ch',
  storeCountryId: 'ch',
  flagImage: '/static/images/flag-ch.png',
  switchLabel: 'Switch to CH',
  landingPage: '/ch/',
};
const FR_STORE = {
  prefix: '/be',
  storeCountryId: 'be',
  flagImage: '/static/images/flag-BE.png',
  switchLabel: 'Switch to BE',
  landingPage: '/be',
};
/*
const DE_STORE = {
  prefix: '/de',
  storeCountryId: 'de',
  flagImage: '/static/images/flag-de.png',
  switchLabel: 'Switch to DE',
  landingPage: '/de/',
};
*/

// TODO enable DE for other envs when it ready
export const availableStores = [
  US_STORE,
  UK_STORE,
  CH_STORE,
  FR_STORE,
];


export const getCountryById = id => availableStores.find(s => s.storeCountryId === id);
