import { Obj } from './Obj';

export class SentryLogFactory {
  static build(store) {
    if (!store || typeof store !== 'object') {
      return {};
    }

    return {
      accountDashboardStore: Obj.getDepth(store.accountDashboardStore, 2),
      magicLinkStore: store.magicLinkStore,
      newsletterStore: store.newsletterStore,
      checkoutStore: Obj.getDepth(store.checkoutStore, 2),
      uiStore: Obj.getDepth(store.uiStore),
      ...Obj.getDepth(store),
    };
  }
}
