export class Obj {
  static getDepth(obj, depth = 1) {
    if (!obj) return {};

    return Object.keys(obj)
      .reduce((map, key) => {
        if (typeof obj[key] === 'object') {
          if (depth - 1 > 0) {
            map[key] = Obj.getDepth(obj[key], depth - 1);

            return map;
          }

          return map;
        }

        map[key] = obj[key];

        return map;
      }, {});
  }
}
