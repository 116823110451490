import React from 'react';
import { withRouter } from 'next/router';
import { observer, inject } from 'mobx-react';
import Navigation from 'components/header/navigation';

@withRouter
@inject('mobxStore')
@observer
class Header extends React.Component {
  constructor(props) {
    super(props);

    if (typeof window !== 'undefined') {
      const body = document.getElementsByTagName('body')[0];
      body.setAttribute('data-user-agent', navigator.platform);
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (document.getElementById('footer')) {
      const totalHgt = document.documentElement.scrollHeight;
      const html = document.getElementsByTagName('html')[0];
      const currentHgt = window.pageYOffset;
      if ((currentHgt + window.innerHeight) > (totalHgt - 2)) {
        if (window.matchMedia('(min-width: 993px)').matches) {
          html.classList.remove('footer-active');
          html.classList.add('footer-expand');
          if (html.classList.contains('footer-expand-trigger')) {
            html.classList.remove('footer-expand');
            html.classList.remove('footer-expand-trigger');
          }
        }
      } else if ((currentHgt + window.innerHeight + 20) <= ((totalHgt - 2) - document.getElementById('footer').scrollHeight)) {
        html.classList.remove('footer-expand');
      }
    }
  }

  render() {
    const {
      mobxStore: {
        checkoutStore,
        uiStore: {
          storeType,
        },
      },
      router,
      activeItem,
    } = this.props;

    const isHomeSelectionRoute = router.pathname === '/store';
    const isCheckoutRoute = router.pathname === '/store/checkout';
    const isConfigurator = router.pathname === '/configurator';
    const isAddMembership = router.pathname === '/store/add-membership';
    const isLegal = router.pathname.includes('/legal');
    // Header is not required in configurator page

    if (typeof window !== 'undefined') {
      const body = document.getElementsByTagName('body')[0];
      if (isConfigurator) {
        body.classList.add('configurator-body');
        return null;
      }
      body.classList.remove('configurator-body');
    }


    return (
      <Navigation
        isHomeSelectionRoute={isHomeSelectionRoute}
        isCheckoutRoute={isCheckoutRoute}
        isAddMembership={isAddMembership}
        isLegal={isLegal}
        activeItem={activeItem}
        checkoutStore={checkoutStore}
        storeType={storeType}
      />
    );
  }
}

export default Header;
