// NOTE: This require will be replaced with `@sentry/browser` when
// process.browser === true thanks to the webpack config in next.config.js
const Sentry = require('@sentry/node');
const SentryIntegrations = require('@sentry/integrations');
const Cookie = require('js-cookie');
const env = require('env');
const packageJson = require('../../package');

module.exports = () => {
  const sentryOptions = {
    dsn: env.SENTRY_PUBLIC_DSN,
    environment: env.SENTRY_ENVIRONMENT,
    release: `${packageJson.version}_${env.COMMIT_HASH}`,
    maxBreadcrumbs: 50,
    ignoreErrors: [/connect ECONNREFUSED 127\.0\.0\.1:3030/],
  };

  // When we're developing locally
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    /* eslint-disable-next-line global-require */
    const sentryTestkit = require('sentry-testkit');
    const { sentryTransport } = sentryTestkit();

    // Don't actually send the errors to Sentry
    sentryOptions.transport = sentryTransport;

    // Instead, dump the errors to the console
    sentryOptions.integrations = [
      new SentryIntegrations.Debug({
        // Trigger DevTools debugger instead of using console.log
        debugger: false,
      }),
    ];
  }

  Sentry.init(sentryOptions);

  return {
    Sentry,
    captureException: (err, ctx, extraData = null) => {
      Sentry.configureScope((scope) => {
        scope.setLevel('error');
        scope.setTag('event', String(err));

        if (extraData && typeof extraData === 'object') {
          Object
            .keys(extraData)
            .forEach((key) => {
              scope.setExtra(key, extraData[key]);
            });
        }

        if (err.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message]);
        }

        if (err.statusCode) {
          scope.setExtra('statusCode', err.statusCode);
        }

        if (ctx) {
          const {
            req, res, errorInfo, query, pathname,
          } = ctx;

          if (res && res.statusCode) {
            scope.setExtra('statusCode', res.statusCode);
          }

          if (process.browser) {
            scope.setTag('ssr', false);
            scope.setExtra('query', query);
            scope.setExtra('pathname', pathname);

            // On client-side we use js-cookie package to fetch it
            const sessionId = Cookie.get('connect.sid');
            if (sessionId) {
              scope.setUser({ id: sessionId });
            }
          } else {
            scope.setTag('ssr', true);
            scope.setExtra('url', req.url);
            scope.setExtra('method', req.method);
            scope.setExtra('headers', req.headers);
            scope.setExtra('params', req.params);
            scope.setExtra('query', req.query);

            // On server-side we take session cookie directly from request
            if (req.cookies && req.cookies.sid) {
              scope.setUser({ id: req.cookies.sid });
            }
          }

          if (errorInfo) {
            Object.keys(errorInfo).forEach(key => scope.setExtra(key, errorInfo[key]));
          }
        }
      });

      return Sentry.captureException(err);
    },
  };
};
