import React, { Component } from 'react';

export default class IconNavArrowDown extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="5"
        viewBox="0 0 8 5"
      >
        <path
          fill="none"
          fillRule="evenodd"
          stroke={this.props.strokeColor ? this.props.strokeColor : '#3C396F'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7 1L4 4 1 1"
        />
      </svg>
    );
  }
}
