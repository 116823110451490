import React from 'react';
import css from './style.css';

class Microdata extends React.Component {
  render() {
    return (
      <a href="/" title="Plume Home WiFi" className={css['microdata-container']} itemScope itemType="http://schema.org/Organization">
        <span itemProp="name">Plume Home WiFi</span>
        <img itemProp="logo" src="/static/images/plume-logo.png" alt="Plume Home WiFi" />
        <link itemProp="url" href="https://www.plume.com" />
      </a>
    );
  }
}

export default Microdata;
