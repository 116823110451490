import React, { Component } from 'react';

export default class IconLinkedIn extends Component {
  render() {
    return (
      <svg
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 31 31"
        xmlSpace="preserve"
      >
        <style>{'.st1{fill:#95223d}'}</style>
        <ellipse cx={15.5} cy={15.5} rx={15.2} ry={15.2} fill="#fff" />
        <path className="st1" d="M7.3 12.6H10.7V22.9H7.3z" />
        <path
          className="st1"
          d="M10.9 9.4c0-1-.7-1.8-1.9-1.8s-1.9.8-1.9 1.8.7 1.8 1.9 1.8c1.2-.1 1.9-.8 1.9-1.8zM23.1 17c0-3.2-1.7-4.6-4-4.6-1.8 0-2.6 1-3.1 1.7v-1.5h-3.4v10.3H16v-5.8c0-.3 0-.6.1-.8.2-.6.8-1.3 1.8-1.3 1.2 0 1.7.9 1.7 2.3v5.5H23c.1.1.1-5.8.1-5.8z"
        />
      </svg>
    );
  }
}
