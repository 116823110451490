import React, { Fragment } from 'react';

import { observer, inject } from 'mobx-react';
import { withRouter } from 'next/router';

import { withTranslation } from 'server/i18n';
import HeaderMini from 'components/header-mini';
import Microdata from '../microdata';

import css from './style.css';

/**
 * @deprecated
 */

@withTranslation('navigation')
@withRouter
@inject('mobxStore')
@observer
class NavigationDesktop extends React.Component {
  render() {
    return (
      <Fragment>
        <Microdata />
        <div id="header" className={css.header}>
          <div className="container">
            <div className={css['navigation-desktop']}>
              <HeaderMini />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default NavigationDesktop;
