import React, { Component } from 'react';

export default class IconFacbebook extends Component {
  render() {
    return (
      <svg x="0px" y="0px" viewBox="0 0 31 31" xmlSpace="preserve">
        <ellipse cx={15.5} cy={15.5} rx={14.9} ry={14.9} fill="#fff" />
        <path
          d="M17.7 23.8v-8.3h2.5l.3-2.9h-2.8v-1.4c0-.7.1-1.1 1.2-1.1h1.5V7.2H18c-2.9 0-4 1.4-4 3.7v1.7h-1.8v2.9H14v8.3h3.7z"
          fill="#95223d"
        />
      </svg>
    );
  }
}
