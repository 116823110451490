import React, { Component } from 'react';
import { Link } from 'server/next-routes';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'server/i18n';
import { withRouter } from 'next/router';

import cx from 'classnames';
import IconNavArrowDown from 'components/icons/NavArrowDown';

import css from './style.css';

const MOBILE_VIEW = 767;

@withTranslation('common')
@withRouter
@inject('mobxStore')
@observer
class UserActions extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      menuOpen: false,
      isMobile: false,
      isDev: false,
    };

    this.closeMenu = this.closeMenu.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  componentDidMount() {
    this.updateResize();
    this._isMounted = true;
    document.addEventListener('click', this.closeMenu.bind(this));
    window.addEventListener('resize', this.updateResize);


    if (typeof window !== 'undefined') {
      this.setState({ isDev: window.location.href.includes('dev') });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('click', this.closeMenu.bind(this));
    window.removeEventListener('resize', this.updateResize);
  }

  updateResize = () => {
    const isMobile = window.matchMedia(`(max-width: ${MOBILE_VIEW}px)`).matches;
    this.setState(state => ({
      ...state,
      isMobile,
    }));
  };


  getLink = (link) => {
    const {
      urlPrefix,
    } = this.props;
    return `${urlPrefix || ''}${link}`;
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    });
  }

  handleToggleMenu = () => {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen,
    }));
  }

  closeMenu(event) {
    if (!event.target.closest('#userActionsMenu, #userActions')) {
      // we prevent setting state if component unmounted already
      if (this._isMounted) {
        this.setState({
          menuOpen: false,
        });
      }
    }
  }

  handleSignOut() {
    const {
      signOut,
      // country,
    } = this.props;

    signOut();

    window.location.href = 'https://www.plume.com/homepass/';
  }

  renderNavLink = (isMobile, t) => (isMobile ? (
    <Link route={this.getLink('/dashboard')}>
      <a
        className={cx('regular', 'nav-links', css['header-mini-text'], css.navLink)}
      >
        {t('common:account', 'Account')}
      </a>
    </Link>
  ) : (
    <span className={cx('regular', 'nav-links', css['header-mini-text'])}>
      {t('common:account', 'Account')}
    </span>
  ))

  render() {
    const {
      t,
      userName,
      toggleMenu,
      country,
      router,
    } = this.props;

    const
      {
        mobxStore: {
          accountStore: {
            localAccount,
          },
        },
      } = this.props;

    const {
      menuOpen,
      isMobile,
      isDev,
    } = this.state;

    let postfix = '/homepass';

    if (router.pathname.includes('/virginmedia')) {
      postfix = '/virginmedia';
    }

    const cloudCountry = {
      us: 'gamma',
      de: 'kappa',
      be: 'kappa',
      uk: 'kappa',
      cy: 'kappa',
    };

    const redirectCountry = {
      us: `https://shop.plume${isDev ? '-develop' : ''}.com%2Fauth%3Fnext%3Dadd-pods`,
      de: `https://shop.plume${isDev ? '-develop' : ''}.com%2Fde%2Fauth%3Fnext%3Dadd-pods`,
      be: `https://shop.plume${isDev ? '-develop' : ''}.com%2Fbe%2Fauth%3Fnext%3Dadd-pods`,
      uk: `https://shop.plume${isDev ? '-develop' : ''}.com%2Fgb%2Fauth%3Fnext%3Dadd-pods`,
      cy: `https://shop.plume${isDev ? '-develop' : ''}.com%2Fcy%2Fauth%3Fnext%3Dadd-pods`,
    };

    // const { host } = window ? window.location : { host: '' };

    const partnerId = localAccount
      && localAccount.accountData
      && localAccount.accountData.basicInfo
      && (
        localAccount.accountData.basicInfo.crmId
        || localAccount.accountData.basicInfo.ClaimingPartnerID__c
      );
    const cloud = isDev ? 'dogfood' : cloudCountry[country];
    // TODO adjust domain https://shop.plume-develop.com/
    const redirectUrl = redirectCountry[country];

    const addPodsLink = `https://account${isDev ? '-dev' : ''}.plume.com/login?redirect_uri=${redirectUrl}&cloud=${cloud}&client_id=plumeWebStore&partner_id=${partnerId}`;

    const hideAddPods = [
      '6407095ceb914d000bc41cb5',
      '628fe4fc5d6ebb2fe3df4c07',
      '61794405a4848654528c7ab1',
    ].includes(partnerId);

    return (
      <div className={cx('sunrise-user-actions', css['user-actions'])}>

        <div
          id="userActions"
          className={css['user-actions-user']}
          onClick={() => {
            this.handleToggleMenu();
            toggleMenu();
          }}
        >
          {this.renderNavLink(isMobile, t)}
          {!isMobile && (
            <span className={cx('drop-arrow', {
              [css['user-actions-user-text']]: true,
              [css.active]: menuOpen,
            })}
            >
              <span className={css['clip-text']}>{userName}</span>
              <IconNavArrowDown />
            </span>
          )}
        </div>
        {isMobile && (
          <div className={css['user-actions-user']}>
            <a
              href={addPodsLink}
              className={cx('regular', 'nav-links', css['header-mini-text'], css.link)}
              id="userActionsMenu__buyPods"
              onClick={toggleMenu}
            >
              {t('common:show-addons', 'Buy more pods')}
            </a>
          </div>
        )}
        {isMobile && (
          <div className={css['user-actions-user']}>
            <a
              href={`https://www.plume.com${country === 'de' ? '/de' : ''}${country === 'be' ? '/be' : ''}${country === 'uk' ? '/gb' : ''}${postfix}/shop`}
              className={cx('regular', 'nav-links', css['header-mini-text'], css.link)}
              id="userActionsMenu__store"
              onClick={this.handleMenuClose}
            >
              {t('common:shop-starter-packs.title', 'Buy Plume membership')}
            </a>
          </div>
        )}
        {isMobile && (
          <div
            className={css['user-actions-mobile-signout']}
            onClick={() => {
              this.handleSignOut();
              toggleMenu();
            }}
          >
            <span className={cx('regular', 'nav-links', css['header-mini-text'])}>
              {t('common:sign-out', 'Sign out')}
            </span>
          </div>
        )}
        {!isMobile && (
          <div
            id="userActionsMenu"
            className={cx({
              [css['user-actions-menu']]: true,
              [css['user-actions-menu-active']]: this.state.menuOpen,
            })}
          >
            {
              country === 'ch' ? (
                <>
                  <div className={css['user-actions-menu-item']}>
                    <a
                      href="https://smarthome.plume.com/ch/sunrise/store/buy-pods"
                      className={cx('accent-text-color', css['buy-pods'], css.link)}
                      id="userActionsMenu__buyPods"
                      onClick={this.handleMenuClose}
                    >
                      {t('common:show-addons', 'Buy more pods')}
                    </a>
                  </div>
                  <div className={css['user-actions-menu-item']}>
                    <span
                      className={cx('accent-text-color')}
                      id="userActionsMenu__signout"
                      onClick={this.handleSignOut}
                    >
                      {t('common:sign-out', 'Sign out')}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {
                    hideAddPods ? (<></>) : (
                      <>
                        <div className={css['user-actions-menu-item']}>
                          <a
                            href={addPodsLink}
                            className={cx('accent-text-color', css['buy-pods'], css.link)}
                            id="userActionsMenu__buyPods"
                            onClick={this.handleMenuClose}
                          >
                            {t('common:show-addons', 'Buy more pods')}
                          </a>
                        </div>
                      </>
                    )
                  }
                  <div className={cx(css['user-actions-menu-item'], css['user-actions-menu-item-exposed'])}>
                    <a
                      href={`https://shop.plume${isDev ? '-develop' : ''}.com${country === 'de' ? '/de' : ''}${country === 'be' ? '/be' : ''}${country === 'uk' ? '/gb' : ''}${postfix}/select-hardware`}
                      className={cx('accent-text-color', css.link)}
                      id="userActionsMenu__store"
                      onClick={this.handleMenuClose}
                    >
                      {t('common:shop-starter-packs.title', 'Buy Plume membership')}
                      <small>
                        {t('common:shop-starter-packs.description', 'If you want to set up a new location with Plume.')}
                      </small>
                    </a>
                  </div>
                  <div className={css['user-actions-menu-item']}>
                    <span
                      className={cx('accent-text-color')}
                      id="userActionsMenu__signout"
                      onClick={this.handleSignOut}
                    >
                      {t('common:sign-out', 'Sign out')}
                    </span>
                  </div>
                </>
              )
            }
          </div>
        )}
      </div>
    );
  }
}

UserActions.defaultProps = {
  toggleMenu: () => {},
};

export default UserActions;
