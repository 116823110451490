import React, { Component } from 'react';

export default class SunriseWhite extends Component {
  render() {
    return (
      <svg viewBox="0 0 146 32">
        <path
          d="M23.3 4.3l-4.8 5C17.3 8.1 16 7 14.3 6.9c-1.1-.1-2.3 0-3.4.3-1 .2-1.6 1.1-1.7 2.2-.1 1.1.5 1.9 1.4 2.3 1.3.6 2.7 1 4 1.5 1.2.4 2.4.8 3.5 1.4 4.8 2.4 6.4 7.6 3.6 12.4-1.5 2.6-3.8 4-6.6 4.6-5.1 1.1-9.7.2-13.9-2.9-.4-.3-.8-.7-1.2-1.1l5-4.9c1.4 1.5 3.2 2.7 5.4 2.9 1 .1 2 0 3-.3 1.2-.3 1.8-1.2 1.9-2.4.1-1.2-.4-2.1-1.4-2.6-1.4-.7-2.8-1.1-4.3-1.7-1.3-.5-2.7-1-4-1.7C0 14 .5 6.1 4.6 3 7.3.9 10.3.2 13.6.3c3.4.3 6.4 1.3 9 3.4.2.1.4.3.7.6m116 13.7c.2-2.1-1.4-3.8-3.5-3.9-3-.2-5.2 1.5-5.3 3.9h8.8zm.9 6.2l4.8 3c-1.1 1.6-2.4 2.7-4.1 3.4-3.9 1.6-7.8 1.6-11.6-.3-3.9-2-6-6.2-5.6-10.8.9-9.5 10.3-12.1 16.3-9.3 3.5 1.6 5.1 4.5 5.7 8.2.2 1.3.2 2.6.3 4h-15.5c.2 1.8 1.2 2.8 2.6 3.4 2.6 1.2 5 .7 7.1-1.6zm-99.5 4c-2.1 3-4.9 3.9-8.1 3.4-3.3-.5-5.1-2.7-5.8-5.8-.2-.8-.3-1.6-.3-2.5V10.5c0-.2 0-.4.1-.6h7v1c0 3.9 0 7.8.1 11.6 0 1.8 1 3 2.5 3.2 1.7.3 3.2-.5 3.8-2.2.3-.8.5-1.7.5-2.5.1-3.4 0-6.8 0-10.3v-1h7v21.1h-6.7c-.1.3-.1-2.6-.1-2.6zm32.1 2.9h-7v-1-10.5c0-.6-.1-1.3-.2-1.9-.3-1.1-.8-2.1-2-2.4-1.4-.4-2.6-.1-3.5 1-.9 1-1.1 2.2-1.1 3.5V31h-7V9.9h6.7v2.7c1.1-1.3 2.3-2.3 3.8-2.8 3.7-1.4 9.3-.1 10.2 6 .2 1.2.2 2.5.2 3.8-.1 3.8-.1 7.6-.1 11.5m49.1-18.8c-1.4 1.3-2.7 2.5-4 3.7-1.3-1-2.7-1.9-4.6-1.8h-.6c-1.1.2-1.8.7-1.8 1.5 0 1.1.8 1.4 1.6 1.6 1.1.3 2.3.6 3.4.9.7.2 1.4.4 2.1.8 2.1 1 3.5 2.6 3.6 5 .1 2.7-.9 4.8-3.2 6.2-3 1.7-6.2 1.7-9.4 1-2.1-.5-4-1.3-5.7-2.9l3.8-4.1c.8.6 1.6 1.2 2.4 1.6 1.3.7 2.6.9 4 .5.8-.2 1.3-.7 1.4-1.5 0-.9-.5-1.4-1.2-1.6-1-.4-2.1-.6-3.2-1-.5-.1-1-.3-1.4-.4-2.6-.9-4.4-2.4-4.6-5.3-.2-2.8 1-4.8 3.4-6.2 3.9-2 10.8-1.2 14 2m-31.4 3.2c-.9 0-1.6-.1-2.3 0-2.7.3-4.4 2.2-4.4 5v10.6h-6.9V10h6.6v3c.3-.4.4-.5.5-.7 1.4-2.1 3.4-3.1 6-3 .6 0 .9.2.8.9-.1 1.8-.2 3.6-.3 5.3m3.4 15.6h6.9V10h-6.9v21.1zm3.4-23.8c-2.2 0-3.8-1.6-3.8-3.6S95.2.1 97.3.1c2.2 0 3.8 1.5 3.9 3.6-.1 2-1.7 3.6-3.9 3.6"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#fff"
        />
      </svg>
    );
  }
}
