import React from 'react';
import cx from 'classnames';
import { observer, inject } from 'mobx-react';
import { Link } from 'server/next-routes';
import { withRouter } from 'next/router';
import { withTranslation } from 'server/i18n';
import { availableStores } from 'constants/storeCountry';
import IconTwitter from 'components/icons/Twitter';
import IconLinkedIn from 'components/icons/LinkedIn';
import IconFacbebook from 'components/icons/Facebook';
import SunriseWhite from 'components/icons/SunriseWhite';
import IconPlumeWhite from 'components/icons/PlumeWhite';
import css from './style.css';

@withTranslation('footer')
@withRouter
@inject('mobxStore')
@observer
class Footer extends React.Component {
  scrollTimeout

  resizeTimeout

  constructor(props) {
    super(props);


    this.toggleFooter = this.toggleFooter.bind(this);
  }


  componentDidMount() {
    this.props.router.events.on('routeChangeComplete', () => {
      this.closeFooter();
    });
  }

  closeFooter = () => {
    const htmlTag = document.getElementsByTagName('html')[0];
    htmlTag.classList.remove('footer-active');
  }

  openFooter = () => {
    const htmlTag = document.getElementsByTagName('html')[0];
    htmlTag.classList.add('footer-active');
  }

  toggleFooter = () => {
    const htmlTag = document.getElementsByTagName('html')[0];
    if (!htmlTag.classList.contains('footer-active')) {
      this.openFooter();
    } else {
      this.closeFooter();
    }
  }


  renderStoreCountrySwitcher = () => {
    const {
      mobxStore: {
        country,
        accountStore: {
          localAccount,
        },
        setStoreCountry,
      },
    } = this.props;

    if (localAccount && localAccount.loading) {
      return null;
    }

    return availableStores.map((store) => {
      if (store.storeCountryId !== country) {
        return (
          <button
            id="footer__language"
            key={`switch-button-${store.switchLabel}`}
            className={css['footer-language']}
            onClick={() => {
              setStoreCountry(store.storeCountryId);
            }}
          >
            <p className={cx('default-m', 'semi-bold', css['footer-language-text'])}>{store.switchLabel}</p>
            <img src={store.flagImage} alt={store.storeCountryId} />
          </button>
        );
      } return null;
    });
  }

  render() {
    const
      {
        router,
      } = this.props;

    const isCheckoutRoute = router.pathname === '/sunrise/store/checkout';
    const isConfigurator = router.pathname === '/sunrise/configurator';
    const isDashboard = router.pathname === '/sunrise/dashboard';
    const isStore = router.pathname === '/sunrise/store';
    const isStoreWithSlash = router.pathname === '/sunrise/store/';
    const isBuypods = router.pathname === '/sunrise/store/buy-pods';
    const isMembershipRoute = router.pathname === '/sunrise/membership';
    if (
      isCheckoutRoute
      || isConfigurator
      || isStore
      || isBuypods
      || isMembershipRoute
      || isDashboard
      || isStoreWithSlash
    ) {
      return null;
    }


    return (
      <div className={cx('footer-up', css.sunriseFooter)}>
        <div className={css.footerContainer}>
          <div className={css.footerWrapper}>
            <div className={css.footerRHS}>
              <a className={css.footerLegal} rel="noopener noreferrer" target="_blank" href="https://discover.plume.com/sunrise/legal">Rechtliche Hinweise</a>
              <div className={css.footerSocial}>
                <ul>
                  <li>
                    <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/plumewifi/">
                      <IconFacbebook />
                    </a>
                  </li>
                  <li>
                    <a rel="noopener noreferrer" target="_blank" href="http://www.linkedin.com/company/plume-wifi/">
                      <IconLinkedIn />
                    </a>
                  </li>
                  <li>
                    <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/plume">
                      <IconTwitter />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={css.footerLHS}>
              <div className={css.footerLogos}>
                <Link route="/">
                  <a className={css.footerLogoSunrise}><SunriseWhite /></a>
                </Link>
                <Link route="/">
                  <a className={css.footerLogoPlume}><IconPlumeWhite /></a>
                </Link>
              </div>
              <div className={css.sunriseTrademarks}>
                <h3>Handelsmarken</h3>
                <p>
                  Mehr Pods bedeuten eine bessere Konnektivität,
                  insbesondere für größere Projekte.
                  Du kannst über die Checkout-Seite von Plume weitere Pods
                  zu deiner Bestellung hinzufügen.
                </p>
              </div>
              <div className={css.footerCopyright}>
                <p>© 2020 Plume Design, Inc</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
