import React, { Fragment } from 'react';
import App from 'next/app';
import Head from 'next/head';
import {
  GOOGLE_TAG_MANAGER_KEY,
  VWO_ACCOUNT_ID,
} from 'env';
import { Provider, observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import { getCookie } from 'utils/Cookies';
import { initStore } from 'store';
import { getUserAgent, isAndroid } from 'utils/userAgent';
import { COOKIE_ALERT_NAME } from 'constants/cookies';
import Layout from 'components/layout';
import { getInitialSnapshot } from 'utils/mobx';
import { appWithTranslation } from 'server/i18n';
import { SentryLogFactory } from '../utils/SentryLogFactory';
import sentry from '../utils/sentry';

const { captureException } = sentry();

@appWithTranslation
@observer
export default class PlumeApp extends App {
  constructor(props) {
    super(props);
    this.store = initStore(props.initialState);
  }

  static async getInitialProps({ Component, ctx }) {
    const {
      req, res, pathname, query,
    } = ctx;
    const initialSnapshot = await getInitialSnapshot(req, res, pathname, query);
    const store = initStore(initialSnapshot);
    const userAgent = getUserAgent(ctx);

    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    const initialState = getSnapshot(store);

    return {
      initialState,
      pageProps,
      userAgent,
    };
  }

  async componentWillMount() {
    if (process.browser) {
      // if (isCaliforniaUser(location)) {
      //   this.store.uiStore.setCALocation(true);
      //   if (getCookie(COOKIE_ALERT_NAME) === 'true') {
      //     this.store.uiStore.setUseCookie(true);
      //   }
      // } else {
      //   this.store.uiStore.setUseCookie(true);
      // }
      if (getCookie(COOKIE_ALERT_NAME) === 'true') {
        this.store.uiStore.setUseCookie(true);
      }
    }
  }

  componentDidCatch(error, errorInfo) {
    const isBrowser = process.browser;
    let extraData = null;
    if (isBrowser) {
      const storeSnapshot = getSnapshot(this.store);
      extraData = SentryLogFactory.build(storeSnapshot);
    }
    captureException(error, { errorInfo }, extraData);
    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const {
      Component,
      pageProps,
      userAgent,
    } = this.props;
    const {
      uiStore: {
        useCookie,
      },
    } = this.store;
    const android = isAndroid(userAgent);

    return (
      <Fragment>
        <Head>
          {useCookie && (
            <script dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_KEY}');
              `,
            }}
            />
          )}
          {useCookie && (
            <script src="//js.hs-scripts.com/3848927.js" async />
          )}

          {!android && useCookie && (
            <script dangerouslySetInnerHTML={{
              __html: `
                window._vwo_code = window._vwo_code || (function(){
                var account_id=${VWO_ACCOUNT_ID},
                settings_tolerance=2000,
                library_tolerance=2500,
                use_existing_jquery=false,
                is_spa=1,
                hide_element='body',
                /* DO NOT EDIT BELOW THIS LINE */
                f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
                window.settings_timer=setTimeout('_vwo_code.finish()',settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());
              `,
            }}
            />
          )}
        </Head>
        <Provider mobxStore={this.store}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </Provider>
      </Fragment>
    );
  }
}
