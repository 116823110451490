import React, { Component } from 'react';

export default class IconTwitter extends Component {
  render() {
    return (
      <svg viewBox="0 0 31 31">
        <circle cx={15.5} cy={15.5} fill="#fff" r={15} />
        <path
          d="M15 12.7v.5l-.5-.1c-1.9-.2-3.6-1.1-5-2.5l-.6-.6-.2.5c-.4 1.1-.1 2.3.7 3.1.4.4.3.5-.4.2-.3-.1-.5-.1-.5-.1-.1.1.2 1 .4 1.4.3.5.8 1.1 1.4 1.4l.5.2h-.6c-.6 0-.6 0-.5.2.2.7 1 1.4 2 1.7l.7.2-.6.3c-.9.5-1.8.8-2.8.8-.5 0-.9.1-.9.1 0 .1 1.3.7 2 .9 2.2.7 4.9.4 6.9-.8 1.4-.8 2.8-2.5 3.5-4.1.4-.9.7-2.4.7-3.2 0-.5 0-.6.6-1.1.3-.3.7-.7.7-.8.1-.2.1-.2-.4 0-.9.3-1 .3-.6-.2.3-.3.7-1 .7-1.1 0 0-.2 0-.3.1-.2.1-.6.3-.9.4l-.6.2-.5-.3c-.3-.2-.7-.4-.9-.5-.5-.1-1.4-.1-1.8 0-1.4.6-2.2 1.8-2.2 3.2z"
          fill="#95223d"
        />
      </svg>
    );
  }
}
