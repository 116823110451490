import React from 'react';
import { observer, inject } from 'mobx-react';
import { Router } from 'server/next-routes';
import { withRouter } from 'next/router';
import dynamic from 'next/dynamic';

import Head from 'components/head';
import Header from 'components/header';
import Overlay from 'components/common/overlay';
import overlayCSS from 'components/common/overlay/style.css';
import Spinner from 'components/common/spinner';
import Modal from 'components/common/modal';
import SunriseFooter from 'components/promo/sunrise/footer';
// import ShopFooter from 'components/shop/shop-footer';
// import ShopHeader from 'components/shop/shop-header';
import cx from 'classnames';
import css from './styles.css';

const forcePromotionConfig = {
  sunrise: 'sunrise',
  be: 'belgium',
};

function renderActiveOverlay(activeOverlay) {
  return dynamic({
    loader: () => import(`../${activeOverlay}`),
    loading: () => <Spinner className={overlayCSS['overlay-spinner']} />,
    ssr: false,
  });
}
const urlParamsListWithoutScrollOnTop = ['adaptive-wifi', 'ai-security', 'access-control', 'motion-section'];

Router.events.on('routeChangeStart', (url) => {
  const isRouteExist = urlParamsListWithoutScrollOnTop.find(item => url.indexOf(item) !== -1);
  if (!isRouteExist) {
    window.scrollTo(0, 0);
  }
});

@withRouter
@inject('mobxStore')
@observer
class Layout extends React.Component {
  constructor(props) {
    super(props);

    if (typeof window !== 'undefined') {
      const body = document.getElementsByTagName('body')[0];
      body.setAttribute('data-user-agent', navigator.platform);
    }
    this.layoutRef = React.createRef();
  }

  componentDidMount() {
    const {
      router,
      mobxStore: {
        authStore: {
          setAccessToken,
        },
      },
    } = this.props;

    setAccessToken();
    if (typeof window !== 'undefined') {
      this.detectWebpSupport();
    }

    const parseRoutes = router.asPath.split('/');
    const route = Object.keys(forcePromotionConfig).find(promo => parseRoutes.includes(promo));
    const FORCE_PROMOTION_ROUTE = forcePromotionConfig[route];
    if (this.layoutRef && this.layoutRef.current && FORCE_PROMOTION_ROUTE) {
      this.layoutRef.current.classList.add(`theme-promo-${FORCE_PROMOTION_ROUTE}`);
    }
  }

  componentDidUpdate() {
    const {
      router,
    } = this.props;
    const parseRoutes = router.asPath.split('/');
    const route = Object.keys(forcePromotionConfig).find(promo => parseRoutes.includes(promo));
    const FORCE_PROMOTION_ROUTE = forcePromotionConfig[route];
    if (this.layoutRef && this.layoutRef.current && FORCE_PROMOTION_ROUTE) {
      this.layoutRef.current.classList.add(`theme-promo-${FORCE_PROMOTION_ROUTE}`);
    }
  }

  detectWebpSupport = () => {
    if ((navigator.userAgent.indexOf('Safari') !== -1
      && navigator.userAgent.indexOf('Chrome') === -1)
      || navigator.userAgent.match('CriOS')) {
      document.getElementById('layout').classList.add('no-webp');
    } else {
      document.getElementById('layout').classList.add('webp');
    }
  }

  getRedirectUrl = () => {
    switch (this.props.mobxStore.country) {
      case 'ch':
        return '/ch/sunrise';
      case 'uk':
        return '/uk';
      case 'be':
        return '/be';
      default:
        return '/us';
    }
  };

  render() {
    const {
      router,
      children,
      mobxStore: {
        uiStore: {
          activeOverlay,
          setActiveOverlay,
          activeModal,
          setActiveModal,
          isHeaderSticky,
          isHeaderHidden,
        },
        authStore: {
          isAuthenticated,
        },
      },
    } = this.props;
    const FORCE_PROMOTION = router.pathname.includes('/sunrise') ? 'sunrise' : false;
    const isISPRoute = router.pathname.includes('subscription');
    const OverlayContent = activeOverlay ? renderActiveOverlay(activeOverlay) : null;
    const ModalContent = activeModal ? renderActiveOverlay(activeModal) : null;

    if (isISPRoute) {
      // override ALL headers and footer, just bypass children
      return (
        <div
          className="layout isp-layout"
          id="layout"
        >
          {children}
        </div>
      );
    }
    return (
      <>
        <div
          className={`layout ${isAuthenticated ? 'authorized' : ''} ${router.pathname.includes('/store/checkout') ? 'checkout-page' : ''} ${router.pathname.includes('/store/invoice') ? 'invoice-page' : ''}`}
          ref={this.layoutRef}
          id="layout"
        >
          <div id="modal-root" />
          <div className={cx('sticky-header-stack', {
            [css.isSticky]: isHeaderSticky,
            [css.isHidden]: isHeaderHidden,
          })}
          >
            <Head />
            <Header />
          </div>
          {children}

          {
            activeOverlay && (
              <Overlay handleOverlayClose={setActiveOverlay}>
                <OverlayContent />
              </Overlay>
            )
          }
          {
            activeModal && (
              <Modal handleModalClose={setActiveModal}>
                <ModalContent redirectUrl={this.getRedirectUrl()} />
              </Modal>
            )
          }
        </div>
        {FORCE_PROMOTION && FORCE_PROMOTION === 'sunrise' ? <SunriseFooter /> : <></>}
      </>
    );
  }
}

export default Layout;
